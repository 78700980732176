import React from 'react';
import './portal.css';
import Topo from '../../components/Topo';
import ContentSection from '../../components/ContentSection';
import transparencia from '../../imgs/Transparencia.jpg';
import transparencia2 from '../../imgs/Transparencia2.jpg'; // Adicione a nova imagem aqui

const Portal = () => {
    return (
        <section id="portal" className="portal">
            <Topo page="Portal" />
            
            {/* Passando múltiplas imagens para o mesmo ContentSection */}
            <ContentSection title="PORTAL DA TRANSPARÊNCIA" imgs={[transparencia, transparencia2]}>
                <p className="pfirst">
                    Em atenção à determinação contida na Lei 14.611/2023, a empresa vem à público apresentar o seu Relatório de Transparência salarial.
                </p>
                
            </ContentSection>
        </section>
    );
};

export default Portal;
