import React from 'react';
import './ContentSection.css';

const ContentSection = ({ title, imgs, children }) => {
    return (
        <div className="content-section">
            <div className="title">
                <h1>{title}</h1>
            </div>
            <div className="content">
                <div className="image-container">
                    {imgs.map((img, index) => (
                        <img key={index} src={img} alt={`${title} ${index + 1}`} className="content-image" />
                    ))}
                </div>
                <div className="text-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ContentSection;
